<!--钥匙完善率-->
<template>
  <div class="rate_page">
    <!--rate_page_list start-->
    <div class="rate_page_list">
      <div class="personage_head">
        <div class="personage_head_top">
          <div class="personage_head_txt">类型:</div>
          <ul class="personage_head_ul">
            <li @click="changeHead(index)" :class="{ active: index == headIndex }" v-for="(item, index) in 2"
              :key="index">
              {{ index == 0 ? "房源" : "客源" }}
            </li>
            <!-- <li><router-link to="/personage/blackHouse"></router-link></li> -->
          </ul>
        </div>
      </div>
      <ul class="personage_ul" v-if="headIndex == 0">
        <li v-for="(item, index) in list" :key="index">
          <div class="personage_ul_img m-r-20">
            <img :src="item.fang_fengmian_image" alt="" />
            <div class="personage_img_marking">{{ item.fang_level }}</div>
          </div>
          <div class="personage_ul_main f-1">
            <div class="personage_ul_name">{{ item.house_name }}</div>
            <div class="personage_ul_title">
              {{ item.fang_xing }} | {{ item.fang_mianji }}m² | {{ item.dian_number }}次 | {{ item.name }}
            </div>
            <div class="personage_ul_tags">
              <span class="tag" v-for="(em, ind) in item.biaoqian" :key="ind">{{ em }}</span>
            </div>
            <div class="personage_ul_price flex-between">
              <div class="flex">
                <!-- <div class="personage_ul_price_left m-r-20">
                  <span>{{ item.zong_price }}万</span>
                  <img src="../../assets/image/personage/up2.png" alt="" />
                </div> -->
                <div class="personage_ul_price_right">
                  <span>{{ item.can_price }}元/月</span>
                  <!-- <img src="../../assets/image/personage/up1.png" alt="" /> -->
                </div>
              </div>
              <!-- v-if="userInfo.position != 4" -->
              <div class="personage_ul-bot" v-if="userInfo.position != 4">
                <button class="rate_page_btn" @click="deleteBlack(1, item, index)">彻底删除</button>
                <button class="rate_page_btn" style="margin-left: 20px;"
                  @click="removeBlack(1, item, index)">移出黑名单</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="personages_ul" v-else>
        <li v-for="(item, index) in list" :key="index">
          <div class="li-con">
            <div class="li-hd">
              <div class="name">{{ item.name }}</div>
              <div class="sex">{{ item.sex }}</div>
              <div class="sort">{{ getBoothType(item.booth_type) }}</div>
              <div class="tag">{{ item.finish_type }}</div>
              <div class="price">
                预算<span class="color_red">{{ item.price }}元</span>
              </div>
            </div>
            <div class="li-bd">
              <!-- <div class="size">
                <span class="color-9">意向类型：</span>{{ item.purpose_type }}
              </div> -->
              <div class="size">
                <span class="color-9">房源类型：</span>{{ getHouseType(item.house_type) }}
              </div>
              <div class="size">
                <span class="color-9">经纪人：</span>{{ item.broker_name }}
              </div>
            </div>
          </div>
          <div class="personage_ul-bot" v-if="userInfo.position != 4">
            <button class="rate_page_btn" @click="deleteBlack(2, item, index)">彻底删除</button>
            <button class="rate_page_btn" style="margin-left: 20px;" @click="removeBlack(2, item, index)">移出黑名单</button>
          </div>
        </li>
      </ul>
      <div class="paging" v-if="list.length != 0">
        <el-pagination background @current-change="changePage" layout="prev, pager, next, jumper, ->, total"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!--rate_page_list end-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      headIndex: 0, //
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
    };
  },
  methods: {
    //彻底删除
    deleteBlack(type, item, index) {

      this.$confirm("是否彻底删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.deleteBlack({
          type,
          list_id:item.id,
          id: item[type == 1 ? 'house_id' : 'client_id']
        }).then(res => {
          this.page = 1;
          this.list = []
          this.$message({
            type: "success",
            message: res.msg
          });
          this.getBlackList();
        })
      })
    },
    //移除黑名单
    removeBlack(type, item, index) {
      this.$confirm("是否移出黑名单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .removeBlack({
              id: item.id,
            })
            .then((res) => {
              this.page = 1;
              this.list = []
              this.$message({
                type: "success",
                message: res.msg
              });
              this.getBlackList();
            });
        })
        .catch(() => { });
    },
    changePage(e) {
      this.page = e
      this.getBlackList()
    },
    changeHead(index) {
      this.page = 1;
      this.list = []
      this.headIndex = index;
      this.getBlackList();
    },
    //获取列表
    getBlackList() {
      this.$axios[this.headIndex == 0 ? "houseBlackList" : "blackList"]({
        page: this.page,
      }).then((res) => {
        res.data.data.forEach(item => {
          item.biaoqian = item.fang_biaoqian ? item.fang_biaoqian.split(',') : []
        })
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    getBoothType(type) {
      let txt = '';
      switch (type) {
        case 1:
          txt = '展位'
          break;
        case 2:
          txt = 'A'
          break;
        case 3:
          txt = 'B'
          break;
        case 4:
          txt = 'C'
          break;
        case 5:
          txt = 'D'
          break;

        default:
          break;
      }
      return txt;
    }
  },
  created() {
    this.getUserInfo()
    this.getBlackList();
  },
};
</script>

<style lang="less" scoped>
.personage_head {
  padding: 30px 0;
  border-bottom: 1px solid #ebebeb;

  .personage_head_top {
    display: flex;

    &:last-child {
      margin-top: 15px;
    }

    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }

    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;

      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }
      }

      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}

.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  margin-bottom: 10px;
  height: 64px;

  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }

    .active {
      color: #3273f6;
    }

    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }

  .rate_total {
    font-size: 18px;
  }
}

.rate_page_list {
  background: #fff;
  padding: 0 50px;
}

.personage_ul_tags {
  display: flex;
  margin: 0 -5px;

  .tag {
    background: #f5f5f5;
    height: 28px;
    border-radius: 3px;
    padding: 0 20px;
    margin: 5px;
    font-size: 14px;
    color: #999;
    line-height: 28px;
  }
}

.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}

.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}

.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}

.personage_ul {
  li {
    padding: 40px 0;
    display: flex;
    border-bottom: 1px solid #ebebeb;

    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }

      position: relative;

      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }

    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }

      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }

      .personage_ul_price {
        display: flex;
        align-items: flex-end;

        .personage_ul_price_left {
          display: flex;
          align-items: center;

          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }

        .personage_ul_price_right {
          display: flex;
          align-items: center;

          span {
            color: #999999;
            font-size: 18px;
          }
        }

        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }

      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;

        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }

        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }

          margin-right: 10px;
        }

        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }

        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }

        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;

            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}

.personages_ul {
  li {
    padding: 30px 0;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    justify-content: space-between;
    align-items: center;

    .li-con {
      .li-hd {
        display: flex;
        align-items: center;
      }

      .li-bd {
        display: flex;
        align-items: center;
        margin: 0 -25px;
      }

      .name {
        font-size: 18px;
        margin: 5px 20px 5px 0px;
      }

      .sex {
        font-size: 16px;
        margin: 5px 0px;
      }

      .sort {
        background: #d5e3ff;
        border-radius: 3px;
        line-height: 28px;
        color: #3273f6;
        padding: 0px 12px;
        margin: 5px;
      }

      .tag {
        background: rgba(255, 117, 42, 0.1);
        border-radius: 3px;
        line-height: 28px;
        padding: 0px 12px;
        color: #ff752a;
        margin: 5px;
      }

      .price {
        font-size: 18px;
      }

      .size {
        font-size: 16px;
        margin: 10px 25px;
      }
    }
  }
}
</style>
